body {
  margin: 0;
  font-family: "Comfortaa", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  font-size: 72.5%;
  --pagepad: 20%;
  /* --primary: #012e02; */
  --primary: #015303;
}
@media (max-width: 600px){
  html {
    --pagepad: 10%;
  }
}@media (min-width: 1480px){
  html {
    --pagepad: 25%;
  }
}
:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}
