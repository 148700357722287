.loading {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  
  &__container {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  svg {
    margin: 0 15rem;
    animation-name: rotate;
    animation-duration: 0.75s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
