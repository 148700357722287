.slidein {
  top: 0;
  left: 0;
  z-index: 990;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  visibility: hidden;
  overflow-x: hidden;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.445);
  transform: translateX(-100vh);
  &__navi {
    width: 80%;
    background-color: var(--primary);
    &__links {
      list-style: none;
      margin-top: 4rem;
      &__item {
        margin: 2rem 0;
        a {
          padding: 2rem 0;
          color: white;
          display: inline-block;
          font-size: 1.5rem;
          text-decoration: none;
          &.active {
            padding-bottom: 0.2rem;
            border-bottom: 0.3rem solid #fff;
          }
        }
      }
    }
  }
  &__close {
    width: 20%;
  }
}
.slidein--bg {
  background-color: transparent;
}
.slidein--active {
  display: flex;
  visibility: visible;
  pointer-events: inherit;
  transform: translateX(0);
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    visibility 0s linear 0s;
}
