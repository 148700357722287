.bookingpage {
  padding: 0 var(--pagepad);
  font-size: 1.5rem;
  line-height: 2.5rem;

  form {
    max-width: 700px;
    label {
      display: flex;
      padding: 1rem;
      margin: 1rem 0;
      cursor: pointer;
      border-radius: 5px;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background-color: #f1f1f1;
      }
      input,
      select {
        height: 40px;
        width: 230px;
      }

      @media (max-width: 420px) {
        input,
        select {
          width: 50%;
        }
      }
    }
  }
  input[type='submit'],
  input[type='button'] {
    all: unset;
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 4px;
    display: inline-block;
    padding: 0.7rem 2rem;
    background-color: var(--primary);
    &:hover {
      background-color: lighten(#012e02, 15%);
    }
  }
}
