.explorepage {
  font-size: 1.5rem;
  line-height: 2.5rem;
  .intro {
    padding: 0 var(--pagepad);
  }
  .main {
    width: 70%;
    display: flex;
    margin: 0 auto;
    margin-top: 5rem;
    flex-direction: row-reverse;
    justify-content: space-between;
    // flex-wrap: wrap;
    .map {
      margin-left: 2rem;
      width: 50%;
      iframe {
        width: 100%;
        height: 60rem;
        border-radius: 5px;
        box-shadow: 0px 3px 4px #00000029;
      }
    }
    .info {
      width: 50%;
      &__item {
        margin-top: 2rem;
        &__text {
          opacity: 0.8;
          font-size: 1.3rem;
          &.--margin_top {
            margin-top: 3rem;
          }
        }
      }
      &__details {
        .detail {
          margin-top: 3rem;
          display: flex;
          align-items: center;
          &__icon {
            width: 30%;
            margin-right: 2rem;
            &__cover {
              padding: 2rem;
              width: fit-content;
              height: fit-content;
              border-radius: 9999px;
              background-color: #f1f1f1;
              img {
                width: 5rem;
                height: 4rem;
              }
            }
          }
          &__items {
            width: 70%;
          }
        }
      }
    }
    @media (max-width: 600px) {
      display: block;
      .map {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        iframe {
          width: 40rem;
          height: 40rem;
        }
      }
      .info {
        margin-top: 5rem;
        width: 100%;
      }
    }
    @media (min-width: 600px) and (max-width: 880px) {
      .map {
        width: 45%;
      }
      .info {
        width: 65%;
      }
    }
    @media (min-width: 880px) {
      .info {
        &__details {
          .detail {
            max-width: 70%;
          }
        }
      }
    }
  }
}
