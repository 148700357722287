.placespage {
  padding: 0 var(--pagepad);
  p {
    margin-bottom: 7rem;
    font-size: 1.5rem;
  }
  
  .places {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    a {
      all: unset;
      cursor: pointer;
    }
  }
  .pcard {
    margin: 2rem;
    border-radius: 5px;
    box-shadow: 0px 3px 4px #00000029;
    &__top {
      width: 22rem;
      height: 19rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    //   @media (min-width: 600px)and(max-width: 870px) {
    //     width: 18rem;
    //     height: 18rem;
    //   }
    }
    &__bottom {
      padding: 1rem;
      font-size: 1.2rem;
      &__city {
        font-size: 1.5rem;
        font-weight: bold;
      }
      &__state {
        margin: 0.7rem 0 0.9rem;
      }
      &__btn {
        color: white;
        cursor: pointer;
        border-radius: 4px;
        display: inline-block;
        padding: 0.7rem 2rem;
        background-color: var(--primary);
        &:hover {
          background-color: lighten(#012e02, 15%);
        }
      }
    }
  }
  @media (max-width: 800px) {
    .places {
      justify-content: center;
    }
    .pcard {     
      &__top {
          width: 70vw;
      }
    }
  }
}
