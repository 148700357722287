.header__overlay,
.header {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  &.small {
    height: 30rem;
    background-position: center;
  }
}
.header__overlay {
  margin-bottom: 5rem;
}
.header {
  height: 45rem;
  color: white;
  .jumbo {
    height: 50%;
    display: flex;
    font-size: 3.5rem;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .main {
      padding: 0 1rem;
      font-weight: bold;
    }
    .sub {
      // color: #01330298;
    }
  }
  @media (max-width: 600px) {
    .jumbo {
      font-size: 3rem;
    }
    .navi {
      &__links {
        display: none;
      }
      .slidein__open {
        display: block;
      }
    }
  }
}
