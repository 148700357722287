.lodgePage {
  padding: 0 var(--pagepad);
  font-size: 1.5rem;
  line-height: 2.5rem;
  table {
    width: 100%;
    font-size: 2rem;
    text-align: left;
    border-collapse: collapse;
    td,
    th {
      padding: 8px;
      text-align: left;
      border: 1px solid #dddddd;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }
  }
  .info {
    margin-top: 4rem;
    &__text {
      font-size: 1.7rem;
    }
  }
  .detail {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 2rem;
      background-color: #f1f1f1;
      border-radius: 50%;
      width: 8rem;
      height: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 5rem;
      }
    }

    a {
      color: inherit;
      text-decoration: underline;
      text-decoration-color: inherit;
    }
  }
  .note {
    margin-top: 5rem;
  }
}
