.homepage {
  a {
    text-decoration: none;
  }
  .group {
    padding: 4.8rem var(--pagepad);

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 9rem;
      flex-direction: row;
      justify-content: space-around;

      &__item {
        width: 20rem;
        margin: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        &:last-of-type {
          // margin-bottom: 0;
        }
        &__img {
          width: 60%;
        }
        &__text {
          font-size: 2rem;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
  .group--offer {
    .group__list__item__img {
      margin-bottom: 2rem;
    }
  }
  .group--facilities {
    .group {
      &__list {
        &__item {
          &__cover {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20rem;
            width: 20rem;

            background-color: #f1f1f1;
            border-radius: 50%;
            // @media (min-width: 600px) and(max-width: 1030px) {
            //   height: 15rem;
            //   width: 15rem;
            // }
          }
          &__img {
          }
          &__text {
            all: unset;
            text-align: center;
            &__title {
              margin: 2.4rem 0;
              font-size: 2rem;
              font-weight: bold;
            }
            &__content {
              max-width: 25rem;
              line-height: 2rem;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
  .group--places {
    background-color: #f1f1f1;
    .group {
      &__list {
        a {
          all: unset;
        }
        &__item {
          padding: 0;

          position: relative;
          &__img {
            width: 100%;
            height: 19rem;
            border-radius: 0.5rem;
            box-shadow: 0 0 1rem -0.3rem #0000007a;
          }
          &__explore {
            width: 100%;
            height: 19rem;
            display: flex;
            color: white;
            cursor: pointer;
            flex-wrap: wrap;
            border-radius: 0.5rem;
            position: absolute;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background: #0000005e;

            &__text {
              font-size: 1rem;
              font-weight: bold;
              margin-bottom: 2rem;
            }
            &__button {
              font-size: 1em;
              padding: 0.8rem 3rem;
              border-radius: 0.5rem;
              width: fit-content;
              color: var(--primary);
              background-color: #fff;
            }
          }
          &__explore.hidden {
            display: none;

            @media (max-width: 800px) {
              display: flex;
            }
          }
        }
      }
    }
  }
  .lodging {
    display: flex;
    color: white;
    align-items: center;
    padding: 4.5rem var(--pagepad);
    background-color: var(--primary);
    justify-content: space-between;
    &__text {
      &__title {
        font-size: 2rem;
      }
      &__content {
        margin-top: 2rem;
        font-size: 1.5rem;
      }
    }
    &__btn {
      cursor: pointer;
      margin-left: 2rem;
      font-size: 1.2rem;
      padding: 1rem 3rem;
      font-weight: bold;
      color: var(--primary);
      border-radius: 0.5rem;
      background-color: white;
    }
  }

  @media (max-width: 600px) {
    .group {
      padding: 4.8rem 3rem;
      &__title {
        font-size: 2rem;
        &__hr {
          border-width: 0.3rem;
        }
      }
      &__list {
        margin-top: 6rem;
        flex-direction: column;
        align-items: center;
        &__item {
          &:nth-of-type(2) {
            margin: 3rem 0;
          }
          &__img {
            width: 15rem;
          }
          &__text {
            font-size: 1.5em;
            margin-top: 2rem;
          }
        }
      }
    }
    .group--facilities .group__list__item__text__title {
      font-size: 1.5em;
    }
    .lodging {
      text-align: center;
      flex-direction: column;
      &__btn {
        margin-top: 3rem;
      }
    }
    .footer__links {
      text-align: center;
      flex-direction: column;
      &__item {
        margin-bottom: 2rem;
        &:last-of-type {
          margin-bottom: 0;
        }
        &__link {
          li {
            margin: 0.8rem 0;
          }
        }
      }
    }
  }
}
