.contact {
  font-size: 1.5rem;
  padding: 0 var(--pagepad);
  line-height: 2.5rem;
  p {
    margin-bottom: 5rem;
  }
  .detail {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 2rem;
      background-color: #f1f1f1;
      border-radius: 50%;
      width: 8rem;
      height: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 5rem;
      }
    }

    a {
      color: inherit;
      text-decoration: underline;
      text-decoration-color: inherit;
    }
  }
}
