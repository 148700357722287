.footer {
  &__image {
    padding-top: 10rem;
    margin-bottom: -1rem;
    background-color: white;
    img {
      width: 100%;
    }
  }
  &__links {
    display: flex;
    color: white;
    flex-wrap: wrap;
    padding: 4rem var(--pagepad);
    background-color: var(--primary);
    justify-content: space-between;
    &__item {
      margin: 1rem 0;
      padding: 0 2rem;
      &__title {
        font-size: 2rem;
        font-weight: bold;
      }
      &__link,
      a {
        all: unset;
        cursor: pointer;
        li {
          margin: 1rem 0;
          font-size: 1.3em;
          list-style-type: none;
        }
      }
    }
  }
  @media (max-width: 600px) {
    &__links {
      text-align: center;
      flex-direction: column;
      &__item {
        margin-bottom: 2rem;
        &:last-of-type {
          margin-bottom: 0;
        }
        &__link {
          li {
            margin: 0.8rem 0;
          }
        }
      }
    }
  }
}
